@import "~brands/default/styles/globals";
.ds_field_group_wrapper {
	.form_group {
		margin-bottom: 15px;
		font-family: attalecksans_w_regular;
		@include desktop-and-below {
			margin-bottom: 5px;
		}

		& label {
			display: inline-block;
			max-width: 100%;
			margin-bottom: 10px;
			font-weight: bold;
			font-size: 14px;
		}

		& .block_wrapper {
			position: relative;
			display: flex;
			align-items: center;

			input {
				&[type='search'] {
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
				}
			}

			& .form_control_static {
				font-family: attalecksans_w_regular;
				display: block;
				width: 100%;
				height: 36px;
				padding: 8px 0 7px 0;
				font-size: 16px;
				color: $color8;
			}
		}
	}

	.help_block {
		display: block;
		margin-top: 10px;
		margin-bottom: 10px;
		color: #737373;
		font-size: 14px;
		line-height: 14px;
		font-weight: bold;

		@include desktop-and-below {
			margin-bottom: 5px;
			margin-top: 5px;
		}

		& .icon_badgealert:before {
			content: '\e91d';
			color: $color10;
			box-sizing: border-box;
			display: inline-block;
		}

		& .icon_badgealert:after {
			box-sizing: border-box;
			display: inline-block;
		}

		& .icon_badgealert {
			height: 14px;
			width: 14px;
			display: inline-block;
			font-family: 'icoPrimary' !important;
			font-size: inherit;
			vertical-align: top;
			margin-right: 4px;
			vertical-align: top;
			font-style: normal;
			font-weight: normal;
		}
	}

	.form_control {
		font-family: attalecksans_w_regular;
		display: block;
		width: 100%;
		height: 36px;
		min-height: 36px;
		padding: 6px 15px 7px 15px;
		font-size: 16px;
		color: $color8;
		background-color: $color1;
		background-image: none;
		border: 1px solid $field-border-color;
		border-radius: 6px;
		transition: border-color ease-in-out 0.15s;
		&:focus {
			border-color: $field-border-color-focus;
			outline: 0;
		}
		&::-moz-placeholder {
			color: #999999;
			opacity: 1;
		}
		&:-ms-input-placeholder,
		&::-webkit-input-placeholder {
			color: #999999;
		}
		&::placeholder {
			color: #999999;
		}
		&::-ms-expand {
			border: 0;
			background-color: transparent;
		}
		&[disabled],
		&[readonly] {
			opacity: 0.7;
			.ds-input-option {
				opacity: 0.5;
			}
		}
	}

	.form_control[disabled] {
		cursor: not-allowed;
	}

	textarea.form_control {
		min-height: 85px;
		padding: 10px 15px;
		resize: none;
	}
}
.loader {
	height: 30px;
    min-height: 30px;
    margin-right: 20px
}