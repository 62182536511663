@import "~brands/default/styles/globals";
@-webkit-keyframes spinner {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

@keyframes spinner {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.spinner {
	animation: spinner 1s linear infinite;
}
