@import "~brands/default/styles/globals";
:global {
  @mixin ds-icon-rotate($degrees, $rotation) {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation})";
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    transform: rotate($degrees);
  }
  .ds-icon {
    position: relative;
    color: #0568ae;
    display: inline-block;
    font-family: "icoPrimary" !important;
    font-size: 24px;
    height: 23px;
    margin-right: 7px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: none;
    user-select: none;
    vertical-align: middle;
    width: 1em;

    &.ds-icon-error {
      color: #cf2a2a;
    }

    &.ds-icon-fw {
      text-align: center;
      width: (18em / 14);
    }

    &.ds-icon-rotate-90 {
      @include ds-icon-rotate(90deg, 1);
    }
    &.ds-icon-rotate-180 {
      @include ds-icon-rotate(180deg, 2);
    }
    &.ds-icon-rotate-270 {
      @include ds-icon-rotate(270deg, 3);
    }

    &.ds-icon-2x {
      font-size: 2em;
    }

    &.ds-icon-3x {
      font-size: 3em;
    }

    &.ds-icon-4x {
      font-size: 4em;
    }

    &.ds-icon-5x {
      font-size: 5em;
    }
  }

  .ds-icon-arrows-vertical-arrow-down:before {
    content: "\e940";
  }
  .ds-icon-calendarL:before {
    content: "\e916";
  }
  .ds-icon-calendar:before {
    content: "\e915";
  }
  .ds-icon-att-globe:before {
    content: "\e900";
  }
  .ds-icon-hamburger:before {
    content: "\e901";
  }
  .ds-icon-empty-circle:before {
    content: "\e902";
  }
  .ds-icon-empty-circleL:before {
    content: "\e903";
  }
  .ds-icon-secure:before {
    content: "\e907";
  }
  .ds-icon-secureL:before {
    content: "\e909";
  }
  .ds-icon-padlockopen:before {
    content: "\e908";
  }
  .ds-icon-padlockopenL:before {
    content: "\e90a";
  }
  .ds-icon-print:before {
    content: "\e90d";
  }
  .ds-icon-printL:before {
    content: "\e90e";
  }
  .ds-icon-subtractminimize:before {
    content: "\e917";
  }
  .ds-icon-add-maximize:before {
    content: "\e918";
  }

  .ds-icon-expanded {
    transform: rotate(45deg);
    transition: all 0.3s ease-out 0s;
  }
  .ds-icon-expanded:before {
    content: "\e919";
  }
  .ds-icon-icon-collapsed:before {
    content: "\e919";
    transition: all 0.3s ease-out 0s;
  }
  .ds-icon-down:before {
    content: "\e91c";
  }
  .ds-icon-right {
    transform: rotate(-90deg);
  }
  .ds-icon-right:before {
    content: "\e91c";
  }
  .ds-icon-left {
    transform: rotate(90deg);
  }
  .ds-icon-left:before {
    content: "\e91c";
  }
  .ds-icon-badgealert:before {
    content: "\e91d";
  }
  .ds-icon-approval:before {
    content: "\e91e";
  }
  .ds-icon-flatinfo:before {
    content: "\e91f";
  }
  .ds-icon-alert:before {
    content: "\e920";
  }
  .ds-icon-check:before {
    content: "\e921";
  }
  .ds-icon-close:before {
    content: "\e922";
  }
  .ds-icon-tooltip:before {
    content: "\e923";
  }
  .ds-icon-badgealert:before {
    content: "\e91d";
  }
}
