@import "~brands/default/styles/globals";
.tabs_wrapper {
  display: flex;

  > button {
    padding: 20px;
    border-left: 1px solid $color4;
    background: transparent;
    box-shadow: none;
    border-bottom: 0px;
    border-right:0px;
    border-top:0;
    color: $color14;
    font-size:14px;
    font-family: $primary-font-regular;
    cursor: pointer;

    &:last-child {
      border-right: 1px solid $color4;
    }
    &:focus{
      outline: none;
    }
  }

  .tab {

    &_active {
      position: relative;
      border-top: 4px solid $color14;
      border-bottom: 1px solid $color1;
      margin-bottom: -1px;
      padding-top: 16px;
    }

    &_search {
      position: relative;
      border-top: 4px solid $color14;
      border-bottom: 1px solid $color1;
      margin-bottom: -1px;
      padding-top: 16px;
      cursor: pointer;
      color: $color8;
      font-family: $primary-font-regular;
      font-size: 16px;
    }
  }

  .add_new {
    cursor: pointer;

    &.disabled{
      cursor: not-allowed;
    }

    .add_icon {
      fill: $color14;
      width: $icon-xl;
      height:24px;
    }
  }

  .disabled{
    color: $color5;
    cursor: not-allowed;
  }
}




