@import "~brands/default/styles/globals";
.date_picker {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $primary-font-medium;
  font-size: 16px;

  .range_picker_wrapper {
    display: flex;
    border: 1px solid $color4;
    border-radius: 5px;
    background-color: $color3;
    margin-left: 5px;

    .range_picker {
      display: flex;
      margin: 3px 5px 5px 5px;
      align-items: center;
    }
  }
}

.custom_input {
  border: 0;
  font-family: $primary-font-regular;
  font-size: 16px;
  background: transparent;
}

.button_cancel {
  margin: 5px 150px 5px 5px;
}

.button_confirm {
  margin: 5px;
}

.container {
  > :nth-child(2):nth-last-child(3),
  {
    border-right: 1px solid black;
    margin-right: -1px;
  }

  > :nth-child(3):nth-last-child(2) {
    border-left: 1px solid black;
  }

  > :nth-child(3):nth-last-child(3) {
    border-right: 1px solid black;
    margin-right: -1px;
  }

  > :nth-child(4):nth-last-child(2) {
    border-left: 1px solid black;
  }
}