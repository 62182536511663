@import "~brands/default/styles/globals";
.profile__dropdown {
  position: absolute;
  right: -18px;
  width: 250px;
  background: $color8;
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  will-change: max-height;
  z-index: 15;
  border-radius: 0 0 6px 6px;
  line-height: 48px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.35);
  opacity: 0;
  padding: 15px;
  pointer-events: none;

  .dropdown__border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: $color14;
  }

  .section_divider {
    margin: 5px 15px;
    height: 1px;
    background: white;
  }

  &.active {
    max-height: 100vh;
    opacity: 1;
    pointer-events: auto;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  .languages_dropdown {
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    &.active {
      opacity: 1;
      max-height: 100vh;
    }
  }

  .list__item {
    padding: 5px 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a,
    .username,
    &.selected_language {
      color: white;
      font-size: 15px;
      text-decoration: none;
      font-family: "attalecksans_w_regular";
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 21px;
      word-wrap: break-word;
      padding: 3px 15px;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;

      &:not(.username):hover {
        opacity: 0.7;
        border-left: 6px solid $color14;
        color: $color14;
        padding-left: 9px;
      }

      &.padding_wrapper {
        padding-top: 5px 0;
      }
    }

    .language_wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;

      .flag {
        display: flex;
        align-items: center;

        svg {
          max-height: 20px;
          max-width: 35px;
        }

        &.empty_block {
          margin-right: 30px;
        }
      }

      .arrow_down {
        line-height: 15px;

        svg {
          width: 12px;
          height: 12px;
          margin-left: 3px;

          path {
            fill: white;

            g {
              fill: white;
            }
          }
        }
      }
    }

    &.mobile {
      display: block;
      @media only screen and (min-width: 640px) {
        display: none;
      }
    }
  }

  .menu__bar {
    width: 15px;
    height: 2px;
    background-color: $color3;
    display: block;
    margin: 4px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .close_profile_menu {
    position: absolute;
    z-index: 100;
    top: 12px;
    right: 12px;
    cursor: pointer;
    display: none;

    > div {
      width: 15px;
      height: 2px;
      background-color: $color3;
      display: block;
      margin: 4px auto;

      &:first-child {
        -webkit-transform: translateY(3px) rotate(-45deg);
        -ms-transform: translateY(3px) rotate(-45deg);
        -o-transform: translateY(3px) rotate(-45deg);
        transform: translateY(3px) rotate(-45deg);
      }

      &:last-child {
        -webkit-transform: translateY(-3px) rotate(45deg);
        -ms-transform: translateY(-3px) rotate(45deg);
        -o-transform: translateY(-3px) rotate(45deg);
        transform: translateY(-3px) rotate(45deg);
      }
    }

    @media only screen and (max-width: 768px) {
      display: block;
    }
  }

  @media only screen and (max-width: 768px) {
    background: $color8;
    z-index: 100;
    border-radius: 0;
  }
}
