@import "~brands/default/styles/globals";

 .switch {
	position: relative;
	display: inline-block;
	height: 32px;
	 margin-right: 5px;
	 margin-bottom: 5px;
}

.switch input {display:none;}

.slider {
  position: relative;
  cursor: pointer;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  border:1px solid $color4;
  background-color: $color1;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 37px;
  padding-right: 14px;

  > span{
    font-family: $primary-font-light;
    line-height: 14px;
    font-size:16px;
    letter-spacing: 1px;
    color: $color7;
  }
}

.slider:before {
  position: absolute;
  content: "";
  width: 28px;
  height: 28px;
  left: 1px;
  bottom: 1px;
  background-color: $color2;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
  border: solid 1px $color4;
  background-image: linear-gradient(to bottom, $color2, $color3);
}

input:checked + .slider:before {
  background-color: $color20;
  background-image: none;
  border:1px solid $color20;
  left: calc(100% - 29px);
}

input:checked + .slider {
  justify-content: flex-end;
  padding-left: 14px;
  padding-right: 37px;
}

input:disabled + .slider {
  padding-left: 14px;
  padding-right: 14px;
  background-color: $button-disabled-background-color;
  border:1px solid $button-disabled-background-color;
}

input:disabled + .slider:before {
  display: none;
}

input:disabled:checked + .slider  {
  background-color: $color1;
  border:1px solid $border-color;
}