@import "~brands/default/styles/globals";
.footer_container {
  background: $color8;
	font-family: $primary-font-regular;
	border-top: 10px solid $color14;
  color: $color4;
	position: relative;
	padding: 0 0 30px 0;
	
	a {
		color: $color4;
		opacity: 1;
		transition: all 0.4s ease-in-out;
		text-decoration: none;

		&:hover {
			opacity: 0.7;
		}
	}
}

.links_div, .logo{
  height: 100%;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info{
  text-align: center;
  padding: 20px;
}

.links{
  list-style-type: none;
  padding-left:0;
}

.links_div{
  font-size:14px;
}

.item{
  padding-top: 30px;
  text-align: center
}

.copyright{
  font-size: 14px;
}

.logo {
  cursor: pointer;
  padding: 0 0 20px 0;

  svg{
    margin-top:20px;
    width:70px
  }

  > path{
    fill: $color13;
  }
}

.external_link{
  display: inline-block;
  position: relative;
  align-items: center;
  padding-left: 5px;
  height: 25px;
  cursor: pointer;
  width: 25px;

  svg{
    margin-bottom: 2.5px;
  }
}