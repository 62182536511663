@import "~brands/default/styles/globals";
.modalTabs{

  display: flex;
  flex-direction: row;
  height: 100%;
}

.tabsNavigation{

  ul{
    overflow-y: auto;
    margin: 0;
    padding: 0 0 0 30px;
    list-style-type: none;
    border-right: 1px solid #d2d2d2;
    height: 100%;

    li{
      padding: 5px;
      padding-left: 0;
      margin-bottom: 5px;
      cursor: pointer;
      position: relative;
      width: 210px;

      span{
        display: block;
        font-family: "attalecksans_w_light";
        font-size: 14px;
        font-weight: 300;
        line-height: 14px;
        letter-spacing: 0.3px;
        text-align: left;
        color: #0568ae;
        cursor: pointer;
      }

      &.active{
        
        span{
          font-family: "attalecksans_w_medium";
          color: #191919;
          margin-left: 10px;
        }

        &:before{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 4px;
          height: 100%;
          background-color: #0568ae;
        }
      }

      &.disabled{
        >span{
          color: gray;
          cursor: not-allowed;
        }
        cursor: not-allowed;
      }
    }
  }
}

.activeTabContent{
  padding: 0;
  margin: 0;
  padding-left: 30px;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-right: 30px;
}