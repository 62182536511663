@import "~brands/default/styles/globals";
/* NOTIFICATION COMMON SHARED STYLES
   ============================= */
//notification wrapper
$not-wrapper-mt: 10px;
//icon
$not-icon-w: 70px;
$not-icon-b-t-l-r: 8px;
$not-icon-b-b-l-r: $not-icon-b-t-l-r;
$not-icon-svg-w: 31px;
//notification message
$not-msg-b-t-l-r: $not-icon-b-t-l-r;
$not-msg-b-b-l-r: $not-msg-b-t-l-r;
//title
$not-msg-title-font-weight: 500;
$not-msg-line-height: 18px;
$not-msg-title-margin: (0 0 5px 0);
//body
$not-msg-body-font-weight: 400;
$not-msg-body-margin: 0;
$not-msg-body-line-height: $not-msg-line-height;
//message list
$not-msg-list-mt: 10px;
$not-msg-list-padding: (0 0 0 15px);
//close btn
$not-close-top: 15px;
$not-close-right: 15px;
$not-close-color: #ffffff;
$not-close-svg-h: 12px;

$not-msg-desktop-padding: (15px 25px 15px 25px);
$not-msg-mobile-padding: (10px 40px 10px 10px);

$toaster-success: #94CB6D;
$toaster-error: #D44848;
/* ============================= */

.notification_wrapper {
	position: relative;
	width: 100%;
	margin-top: rem($not-wrapper-mt);
	border-collapse: separate;
	overflow: hidden;
	padding: 0 15px;
}// notification wrapper

.notification {
	display: flex;
	//flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	overflow: hidden;
	border-radius: 8px;
	min-height: 80px;

	&.notification_success {
		position: relative;

		.notification_icon {
			background-color: $not-success-bg;
		}
	}

	&.notification_pending {
		position: relative;

		.notification_icon {
			background-color: $not-pending-bg;
		}
	}

	&.notification_error {
		position: relative;

		.notification_icon {
			background-color: $not-error-bg;
		}
	}

	&.notification_warning {
		position: relative;

		.notification_icon {
			background-color: $not-warning-bg;
		}
	}

	&.toaster_success {
		position: relative;
		background-color: $toaster-success;
		border: 1px solid $color19
	}

	&.toaster_error {
		position: relative;
		background-color: $toaster-error;
		border: 1px solid $color10;
	}

	.notification_icon {
		width: $not-icon-w;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			height: $not-icon-svg-w;
		}

		path {
			fill: $not-icon-fill;
		}
	}

	.notification_message {
		color: $not-msg-color;
		text-align: justify;
		width: calc(100% - #{$not-icon-w});
		padding: rem($not-msg-desktop-padding);
		display: flex;
		flex-flow: column;
		justify-content: center;

		@media screen and (max-width: 768px){
			padding: $not-msg-mobile-padding;
		}

		&.error {
			background: $not-error-bg;
		}

		&.confirmation {
			background: $not-success-bg;
		}

		&.info {
			background: $not-pending-bg;
		}

		&.warning {
			background: $not-warning-bg;
		}

		&.default {
			background: $not-msg-bg;
		}

		.notification_message_title{
			margin: rem($not-msg-title-margin);
			font-weight: $not-msg-title-font-weight;
			font-family: $primary-font-medium;
			line-height: rem($not-msg-line-height);
			font-size: rem($primary-font-size);
		}

		.notification_message_body {
			font-family: $primary-font-regular;
			margin: $not-msg-body-margin;
			font-weight: $not-msg-body-font-weight;
			line-height: rem($not-msg-body-line-height);
			font-size: rem($not-msg-body-font-size);
			word-break: break-word;
			word-wrap: break-word;

			.action {
				cursor: pointer;
				text-decoration: underline;
				display: inline-block;
			}
		}

		.notification_message_list {
			padding: rem($not-msg-list-padding);
			margin-top: rem($not-msg-list-mt);

			li {
				list-style-type: circle;
				font-family: $primary-font-regular;
				font-weight: $not-msg-body-font-weight;
				line-height: rem($not-msg-body-line-height);
				font-size: rem($not-msg-body-font-size);
			}
		}
	}

	.notification_close {
		position: absolute;
		top: rem($not-close-top);
		right: rem($not-close-right);
		color: $not-close-color;
		cursor: pointer;
		height: $not-close-svg-h;
		width: $not-close-svg-h;

		svg {
			height: $not-close-svg-h;
			width: $not-close-svg-h;
			path {
				fill: $not-close-color
			}
		}
	}
}
