@import "~brands/default/styles/globals";
:global{
	.spinner__wrapper {
		width: 100%;
		height: 50%;
		min-height: 300px;
		position: relative;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		.spinner__msg {
			font-weight: 400;
			margin-top: 20px;
		}
	}
}
