@import "~brands/default/styles/globals";
.modal_filters{
  min-height: 80px;
  background-color: $color2;
  padding: 10px 30px;
  .title {
    padding-bottom: 10px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.17;
    letter-spacing: 0.6px;
    text-transform: uppercase;
  }
  overflow-y: auto;
}