@import "~brands/default/styles/globals";
.footer_container {
  background: $color8;
	font-family: $primary-font-regular;
	border-top: 10px solid $color14;
	color: $color4;
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	position: relative;
	padding: 20px 10px 15px 21px;

	.footer_container_left,
	.footer_container_right {
		display: flex;
		align-items: flex-start;
	}

	a {
		color: $color4;
		opacity: 1;
		transition: all 0.4s ease-in-out;
		text-decoration: none;

		&:hover {
			opacity: 0.7;
		}
	}
}

.footer_container_left{
	flex: 3 1 auto;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.footer_container_right{
	padding-left: 300px;
	padding-right: 65px;
	justify-content: center;
	margin:auto;
}

.links{
	padding: 0 0 20px 0;
	list-style-type: none;
}

.item{
	margin-right: 0;
	display: inline-block;
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
  height: 75px;
  cursor: pointer;
  width: 75px;

  svg{
    width:70px
  }

  > path{
    fill: $color13;
  }
}

.external_link{
  display: inline-block;
  position: relative;
  align-items: center;
  padding-left: 5px;
  height: 25px;
  cursor: pointer;
  width: 25px;

  svg{
    margin-bottom: 2.5px;
  }
}