@import "~brands/default/styles/globals";
.modalContainer{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0,0,0,0.5);
  overflow-y: auto;
  text-align: center;
}

.stopscrolling {
  height: 100%;
  overflow: hidden;
}

.modal{
  max-width: 100vw;
  position: relative;
  display: inline-block;
  margin-top: 30px;
  overflow: hidden;
  background-color: white;
  border-radius: 8px 8px 0 0;
  border: 1px #ccc solid;
  box-shadow: 1px 1px 7px 1px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  outline: none;

  @media screen and (max-width: 420px) {
    height: calc(100% - 10px);
  }

  @media screen and (max-width: 770px) {
    margin: 5px;
    width: calc(100% - 10px);
  }
  
  &.modalOpened{
    opacity: 1;
  }
}


