@import "~brands/default/styles/globals";
/* COLLAPSIBLE COMMON STYLES
============================= */

$trigger-icon-height: 2px;
$trigger-icon-width: 10px;

/* ============================= */

.trigger {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  align-items: center;
	cursor: pointer;
	padding:15px;
	border-top:1px solid #d2d2d2;

  .trigger__text,
  .trigger__icon {
		display: inline-block;
  }

  .trigger__text {
    flex: 5;
    font-family: $primary-font-regular;
    font-size: 16px;
		transition: color .3s ease-in-out;
		color:#0568ae;
  }

  .trigger__icon {
    height: $trigger-icon-height;
    width: $trigger-icon-width;
    background: #0568ae;
    position: relative;

    &:after {
      transition: all .4s ease-in-out;
      content: ' ';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 2px;
      background: #0568ae;
      transform: rotate(90deg);
    }
  }

  &.open::after {
    transform: rotate(0deg);
	}

}

.trigger__icon {
  transition: transform .4s ease-in-out;

  &.open::after {
    transform: rotate(0deg);
  }
}
