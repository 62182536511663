@import "~brands/default/styles/globals";
:global {
	.ds-wrapper-flow-steps-is-pending {
		cursor: not-allowed;
	}
	.ds-wrapper-flow-steps-is-pending-item {
		pointer-events: none;
	}
	.ds-flow-steps-div {
		height: auto;
		display: flex;
		position: relative;

		>div {
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
				-ms-flex: 1 1 auto;
				flex-basis: auto;
				width: 100%;
			}
			-ms-flex: 1;
			flex: 1;
			margin-right: 5px;
		}
	}
}
