@import "~brands/default/styles/globals";
:global{
  .topbar-menu-open{
    overflow: hidden;
  }
}

.wrapper {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.main_nav {
  display: flex;
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
  height: 58px;
  background: $color8;
  box-shadow: 0 1px 27px 3px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 0 10px 0 52px;
  &.active {
    overflow: visible;
  }
  .menu {
    margin-right: 10px;
    z-index: 15;
    &:hover {
      cursor: pointer;
    }
    &.active .menu__bar {
      &:nth-child(1) {
        -webkit-transform: translateY(5px) rotate(45deg);
        -ms-transform: translateY(5px) rotate(45deg);
        -o-transform: translateY(5px) rotate(45deg);
        transform: translateY(5px) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        -webkit-transform: translateY(-7px) rotate(-45deg);
        -ms-transform: translateY(-7px) rotate(-45deg);
        -o-transform: translateY(-7px) rotate(-45deg);
        transform: translateY(-7px) rotate(-45deg);
      }
    }
  }
  .menu__bar {
    width: 15px;
    height: 2px;
    background-color: $color3;
    display: block;
    margin: 4px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .name {
    font-family: "attalecksans_w_regular";
    font-size: 18px;
    padding-left: 42px;
    display: flex;
    align-items: center;
    > span {
      color: $color3;
      margin-right: 25px;
    }
  }
  .main_nav__left, .main_nav__center, .main_nav__right {
    display: flex;
    align-items: center;
  }
  .main-nav__left {
    order: 1;
    justify-content: flex-start;
    flex: 10;
  }
  .main-nav__center {
    order: 2;
    flex: 2;
    justify-content: center;
  }
  .main-nav__right {
    order: 3;
    justify-content: flex-end;
  }
  .main_nav__center ~ {
    .main_nav__left, .main_nav__right {
      flex: 1 0 0%;
    }
  }
  .main_nav__item {
    flex: 0 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .profile {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
    a {
      color: white;
      font-family: "attalecksans_w_regular";
			text-decoration: none;
			cursor: pointer;
    }
    .profile__info {
      margin: 0px 5px 0px 10px;
    }
  }
  .backdrop {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease-in-out;
    &.active {
      z-index: 15;
      opacity: 1;
      visibility: visible;
    }
	}

	.main_menu_wrapper{
		position: fixed;
		bottom:0;
    left: 0;
    width: calc( 100% + 17px);
		height: 100%;
		overflow-y: auto;
		z-index: -1;
		opacity: 0;
    visibility: hidden;
		&.active {
      z-index: 15;
      opacity: 1;
      visibility: visible;
    }
	}

  .close_menu {
    position: absolute;
    z-index: 100;
    top: 60px;
    left: 262px;
    cursor: pointer;
    display: none;

    @media only screen and (max-width: 768px) {
      &.active{
        display: block;
      }
    }
  }

  .close_menu > div, .__menu > div {
    width: 15px;
    height: 2px;
    background-color: $color3;
    display: block;
    margin: 4px auto;
  }
  .close_menu > div:first-child {
    -webkit-transform: translateY(3px) rotate(-45deg);
    -ms-transform: translateY(3px) rotate(-45deg);
    -o-transform: translateY(3px) rotate(-45deg);
    transform: translateY(3px) rotate(-45deg);
  }
  .close_menu > div:last-child {
    -webkit-transform: translateY(-3px) rotate(45deg);
    -ms-transform: translateY(-3px) rotate(45deg);
    -o-transform: translateY(-3px) rotate(45deg);
    transform: translateY(-3px) rotate(45deg);
  }
}

li.dropdown_title {
  color: $color6;
  font-size: 12px;
  text-decoration: none;
  display: block;
  min-height: 20px;
  line-height: 60px;
  padding: 0 15px 0 15px;
  word-wrap: break-word;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.5, 0.31, 0.84, 0.05);
  position: relative;
  font-family: 'attalecksans_w_regular';
  text-transform: uppercase;
  font-weight: bolder;
}

.main_nav {

  .profile__caret {
    margin-top:4px;
    svg {
      width: 13px;
      height: 13px;
    }
    path {
      fill: $color1;
    }
  }
}


/* mobile devices*/

@media only screen and (max-width: 768px) {
  .main_nav {
    .name > span {
      &:first-child, &:last-child {
        display: none;
      }
    }
    padding: 0 20px;

    .close_menu {
      left: 80%;
      top: 70px;

      > div {
        background-color: $color14;
      }

      &.close_menu_additional_offset {
        top: 130px;
      }
    }

    .name {
      padding-left: 0;
    }
    .main_nav__left {
      flex-direction: row-reverse;
      flex: 1;
      justify-content: space-between;
    }
    .main_nav__right {
      flex: 1;
      justify-content: flex-end;
    }

    .menu.active .menu__bar {
      &:nth-child(1), &:nth-child(3) {
        transform: none;
      }
      &:nth-child(2) {
        opacity: 1;
      }
    }
	}

	.main_menu_wrapper{
		overflow-x: hidden;
	}
}

.tablet_and_desktop{
  display: block;
  @media only screen and (max-width: 640px) {
    display: none;
  }
}

.main_menu_additional_offset > div {
  top: 116px;
}

.profile_menu_wrapper {
  position:relative;
  top: 27px;
}