@import "~brands/default/styles/globals";

.modalHeader{
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 0 30px;

  .headerTitle{
    margin-top: 20px;
    display: flex;
    align-items: center;
    line-height: 1.2;
    font-weight: normal;
    font-size: 24px;
  }

  .closeButton{
    float: none;
    height: 27px;
    background: none;
    cursor: pointer;
    position: relative;
    right: 10px;
    top: 10px;
    width: 27px;
    margin: 0px;
    -webkit-appearance: none;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    -o-border-image: initial;
    border-image: initial;
    font-family: "icoPrimary";
    border: 1px solid;
    border-radius: 50%;
    
    &:before {
      content: "\E922";
      color: #5a5a5a;
      display: block;
      font-size: 18px;
      height: auto;
      position: absolute;
      right: 0px;
      top: -1px;
      bottom: 0px;
      left: 0px;
      transform: translateY(15%);
      width: auto;
      margin: 0px;
      -webkit-tap-highlight-color: transparent;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  }

}