@import '~brands/default/styles/generic/media-queries.scss';
@import '~brands/default/styles/generic/helpers.scss';

$primary-font-medium: 'attalecksans_w_medium';
$primary-font-regular: 'attalecksans_w_regular';
$primary-font-light: 'attalecksans_w_light';
$primary-font-bold: 'attalecksans_w_bold';
$primary-font-italic: 'attalecksans_w_italic';
$icon-font: 'icoPrimary';

/*---------------------------------------------*/
/* NEW STYLES */
/*---------------------------------------------*/

/* Color palette */
$color1: #ffffff;
$color2: #fafafa;
$color3: #f2f2f2;
$color4: #d2d2d2;
$color5: #959595;
$color6: #5a5a5a;
$color7: #333333;
$color8: #191919;
$color9: #ffb81c;
$color10: #cf2a2a;
$color11: #ea7400;
$color12: #71c5e8;
$color13: #009fdb;
$color14: #0568ae;
$color15: #caa2dd;
$color16: #9063cd;
$color17: #702f8a;
$color18: #b5bd00;
$color19: #4ca90c;
$color20: #007a3e;
$color21: #f8f8f8;
$color22: #e2e2e2;

$primary-font-size: 14px;

$active-status: #4ca90c;
$inactive-status: #d2d2d2;
$test-status: #71c5e8;
$terminate-status: #cf2a2a;
$deactivated-status: #959595;
$pending-status: #ffb81c;

//gradient
$gradient_default_first_color: #0568ae; //dark blue
$gradient_default_second_color: #009fdb; //lightblue
$gradient_dark_first_color: #0f0f0f;
$gradient_dark_second_color: #2f2f2f;
$gradient_dark_third_color: #5a5a5a;
$gradient_dark_fourth_color: #959595;
$gradient_dark_second_color_light: #f2f2f2;
$gradient_light_first_color: #ebebeb;
$gradient_light_second_color: #ffffff;

$table_header_text_color: $color7;
$table_separator_color: #d2d2d2;

$border-color: #d9d9d9;

//label
$text-field-label-color: #0568ae;
$text-field-label-color-secondary: #cccccc;
$text-field-label-font: $primary-font-medium;
$text-field-label-third: #191919;

//batch file upload statused
$bfu-status-submitted: #959595;
$bfu-status-scheduled: #ffb81c;
$bfu-status-progress: #71c5e8;
$bfu-status-completd: #4ca90c;
$bfu-status-error: #cf2a2a;

//buttons
$buttons-border-radius: 100px;
$buttons-font-size: 18px;
$buttons-font-family: $primary-font-medium;
$buttons-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
$buttons-padding: 0 19px;
$buttons-line-height: normal;
$buttons-height: 46px;
$buttons-border: none;
$button-outline-background-color: transparent;

//button primary
$button-primary-background-color: $gradient_default_first_color;
$button-primary-gradient-start: #087ac2;
$button-primary-gradient-end: $gradient_default_first_color;
$button-primary-hover-outline-color: #ffffff;
$button-primary-hover-background: #0563a6;
$button-primary-hover-border-color: $button-primary-hover-background;
$button-primary-hover-color: #ffffff;
$button-primary-focus-outline: none;
$button-primary-focus-box-shadow-color: rgba(0, 0, 0, 0.15);

//button warning
$button-warning-color: #ffffff;
$button-warning-background-color: #d16500;
$button-warning-gradient-start: #ea7400;
$button-warning-gradient-end: #d16500;
$button-warning-hover-outline-color: #ffffff;
$button-warning-hover-background: #c96100;
$button-warning-hover-border-color: $button-warning-hover-background;
$button-warning-hover-color: $button-warning-color;
$button-warning-focus-outline: none;
$button-warning-focus-box-shadow-color: rgba(0, 0, 0, 0.15);

//button success
$button-success-color: #ffffff;
$button-success-background-color: #007a3e;
$button-success-gradient-start: #008744;
$button-success-gradient-end: $button-success-background-color;
$button-success-hover-outline-color: #ffffff;
$button-success-hover-background: #007339;
$button-success-hover-border-color: $button-success-hover-background;
$button-success-hover-color: $button-success-color;
$button-success-focus-outline: none;
$button-success-focus-box-shadow-color: rgba(0, 0, 0, 0.15);

//button disabled
$button-disabled-color: #767676;
$button-disabled-background-color: #d2d2d2;

// button danger
$button-danger-background-color: #cf2a2a;
$button-danger-gradient-start: #cf2a2a;
$button-danger-gradient-end: #912626;
$button-danger-background-focus-color: $button-danger-gradient-end;
$button-danger-outline-color: #cf2a2a;

//button default
$button-default-color: #0568ae;
$button-default-background-color: #f2f2f2;
$button-default-gradient-start: #fcfcfc;
$button-default-gradient-end: $button-default-background-color;
$button-default-hover-outline-color: #ffffff;
$button-default-hover-background: #f0f0f0;
$button-default-hover-border-color: $button-default-hover-background;
$button-default-hover-color: $button-default-color;
$button-default-focus-outline: none;
$button-default-focus-box-shadow-color-first: #d2d2d2;
$button-default-focus-box-shadow-color: rgba(0, 0, 0, 0.15);

//button hollow blue
$button-hollow-blue-color: #0568ae;
$button-hover-hollow-blue-color: #f2f2f2;
$button-hollow-blue-background-color: transparent;
$button-hover-hollow-blue-background-color: $button-hollow-blue-color;

//button hollow gray
$button-hollow-gray-color: #5a5a5a;
$button-hover-hollow-gray-color: #f2f2f2;
$button-hollow-gray-background-color: transparent;
$button-hover-hollow-gray-background-color: $button-hollow-gray-color;

//button link
$button-link-color: #0568ae;
$button-link-focus-outline-color: #191919;

//select
$select-border: #959595;
$select-color: $color7;
$select-label-color: #191919;
$select-required-field-color: #cf2a2a;
$select-gradient-start: #fcfcfc;
$select-gradient-end: #f2f2f2;
$select-focus-border: #0568ae;
$select-focus-background-color: #d2d2d2;

$header_background_color: #f6f6f6;

// DS Focus element styling
$ds-focus-height: 145%;
$ds-focus-width: 145%;
$ds-focus-color: #191919;

// DS Checkbox label
$checkbox-border-radius: 3px;
$checkbox-label-font-size: 16px;
$checkbox-label-font-family: $primary-font-regular;
$checkbox-label-font-color: $text-field-label-third;
$checkbox-label-padding: 0 0 0 34px;

// DS Checkbox default state
$checkbox-line-height: 1.4;
$checkbox-width: 24px;
$checkbox-height: 24px;
$checkbox-border-color-default: #d2d2d2;
// DS Checkbox default checked state
$checkbox-border-color-checked: #0568ae;
$checkbox-font-color: $color2;
$checkbox-default-bg: $color2;
// DS Checkbox disabled state
$checkbox-disabled-border-color: $checkbox-border-color-default;
$checkbox-disabled-background-color: $checkbox-disabled-border-color;
// DS Checkbox disabled checked state
$checkbox-disabled-checked-color: #5a5a5a;
$checkbox-disabled-checked-background-color: $checkbox-border-color-default;

// DS Checkbox error state
$checkbox-error-label-color: $bfu-status-error;
$checkbox-error-border-color: $checkbox-error-label-color;
// DS Checkbox error checked state
$checkbox-error-checked-background-color: $checkbox-error-border-color;

// DS Radio input label
$radio-label-padding: $checkbox-label-padding;
$radio-label-font-color: $checkbox-label-font-color;
$radio-label-font-size: $checkbox-label-font-size;
$radio-label-font-family: $checkbox-label-font-family;

// DS Radio default state
$radio-line-height: $checkbox-line-height;
$radio-margin: 0 10px 10px 0;
$radio-width: $checkbox-width;
$radio-height: $radio-width;
$radio-width: $checkbox-width;
$radio-height: $radio-width;
$radio-border-color-default: $select-border;
$radio-background-color-default: $color2;
$radio-border-radius: 50%;
$radio-line-height-default: 2rem;
// DS Radio default checked state
$radio-checked-color: $checkbox-font-color;
$radio-checked-border-color: $color2;
$radio-checked-background: $color14;

// DS Radio disabled state
$radio-disabled-border-color: $checkbox-border-color-default;
$radio-disabled-background: $radio-disabled-border-color;
// DS Radio disabled checked state
$radio-disabled-checked-color: $checkbox-disabled-checked-color;
$radio-disabled-checked-background: $radio-disabled-border-color;

// DS Radio error state
$radio-error-label-color: $checkbox-error-label-color;
$radio-error-border-color: $checkbox-error-border-color;
// DS Radio error checked state
$radio-error-checked-background: $checkbox-error-border-color;

// Pagination
$pagination-color: #0568ae;
$pagination-background-color: #ffffff;
$pagination-active-color: #191919;

//Dashboard
$dashboard-no-charts-icon-color: $color4;
$dashboard-no-charts-text-color: $color5;

@mixin dsfocus() {
	content: '';
	height: $ds-focus-height;
	left: -5px;
	top: -5px;
	outline: 1px dotted $ds-focus-color;
	position: absolute;
	width: $ds-focus-width;
}

@mixin hollowhover() {
	transition-property: background-color, border-color, color;
	transition-duration: 0.3s;
	transition-timing-function: linear;
	transition-delay: 0s;
}

//Field Group
$field-text-color: $color8;
$field-border-color: $color5;
$field-border-color-focus: $color14;
$field-error: $color10;
$field-disabled: $color4;

//Icon Height
$icon-sm: 12px;
$icon-md: 16px;
$icon-lg: 20px;
$icon-xl: 24px;

//Cards
$card-height: 100%;
$card-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.12);

/* Endpoint status colors */
$active-status: $color13;
$inactive-status: $color16;
$test-ready-status: $color15;
$retired-status: $color17;
$purged-status: $color6;
$terminate-status: $color10;

:export {
	color1: $color1;
	color2: $color2;
	color3: $color3;
	color4: $color4;
	color5: $color5;
	color6: $color6;
	color7: $color7;
	color8: $color8;
	color9: $color9;
	color10: $color10;
	color11: $color11;
	color12: $color12;
	color13: $color13;
	color14: $color14;
	color15: $color15;
	color16: $color16;
	color17: $color17;
	color18: $color18;
	color19: $color19;
	color20: $color20;
}
//todo - refactor: Clean once notification is working
/* NOTIFICATION VARS AND COLORS
   ============================= */
$mobile-content-scale: 0.8;

$not-msg-color: $color3;
$not-msg-bg: #303030;
$not-success-bg: $color19;
$not-pending-bg: $color13;
$not-error-bg: $color10;
$not-warning-bg: $color9;
$not-icon-fill: $color3;
$not-msg-body-font-size: 14px;

$tablet-notification-title-scale: 1;
$tablet-notification-message-scale: 1;

$mobile-notification-title-scale: $mobile-content-scale;
$mobile-notification-message-scale: 0.7;

/* NEW ENDPOINTS STATUS COLORS */

$suspended: #d2d2d2;
$provisioned: #ffb81c;
$deactive: #cf2a2a;
$active: #4ca90c;

/* NEW ENDPOINTS STATUS COLORS */

/* SIM ORDER STATUS COLORS */
$pending: $color11;
$received: $color19;
$shipped: $color14;
$completed: #222222;
