@import "~brands/default/styles/globals";
.dropdown {
  position: fixed;
  top: 58px;
  left: 135px;
  width: 250px;
  margin-bottom: 20px;
  background: $color1;
  transition: all .4s ease-in-out;
  z-index: 15;
  border-radius: 0 0 6px 6px;
  line-height: 48px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.35);
  opacity: 0;
  pointer-events: none;

  .dropdown__border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: $color14;
  }

  &.active {
    opacity: 1;
    position: absolute;
    pointer-events: auto;
  }

  &.opened {
    overflow: visible;
  }

  &.active:after {
    opacity: 1;
  }

  .items {
    width: 98%;
    min-height: 100%;
    background-color: $color1;
    border-radius: 0 0 6px 6px;
    position: static;
    padding: 15px 5px 0 0;

    .item {
      padding: 10px 0px;
      position: relative;
      list-style: none;

      a {
        cursor: pointer;
      }

      .dropdown_menu {
        position: absolute;
        top: 2px;
        right: -255px;
        width: 250px;
        margin-bottom: 20px;
        background: $color3;
        transition: all .4s ease-in-out;
        z-index: 15;
        border-radius: 0 6px 6px 0px;
        line-height: 48px;
        opacity: 0;
        padding: 10px 0;
        pointer-events: none;

        li {
          padding: 7px 0;

          a, &.dropdown_title {
            color: $color14;
            font-size: 15px;
            text-decoration: none;
            display: block;
            min-height: 30px;
            line-height: 30px;
            padding: 0 15px;
            word-wrap: break-word;
            opacity: 1;
            transition: opacity 0.3s cubic-bezier(0.5, 0.31, 0.84, 0.05);
            position: relative;
          }

          a:hover {
            opacity: 0.7;
            color: $color6;
          }

          &.dropdown_title {
            color: $color6;
            font-family: 'attalecksans_w_regular';
            text-transform: uppercase;
            font-size: 13px;
            &::after {
              position: absolute;
              content: "";
              left: 16px;
              bottom: 0px;
              width: 86%;
              border-bottom: 1px solid $color4;
            }
          }
        }
      }

      &.section_title {
        color: $color6;
        font-size: 12px;
        text-decoration: none;
        display: block;
        min-height: 20px;
        line-height: 60px;
        padding: 0 15px 0 15px;
        word-wrap: break-word;
        opacity: 1;
        transition: opacity 0.3s cubic-bezier(0.5, 0.31, 0.84, 0.05);
        position: relative;
        font-family: 'attalecksans_w_regular';
        font-weight: bolder;
      }
    }
  }

  .items{

    .item{

      &.section_title::after {
        position: absolute;
        content: "";
        left: 15px;
        bottom: 20px;
        width: 92%;
        border-bottom: 1px solid $color4;
      }

      &.item_last {
        border-top: 1px solid $color4;
        margin-top: 10px;

        svg {
          height: 100%;
          flex: 3;
          text-align: right;
        }

        a {
          display: flex;
          align-items: baseline;
          justify-content: flex-start;

          &:hover {
            border-left: 6px solid transparent;
          }
        }

        .icon {
          width: 20px;
          height: 20px;
          position: relative;
          top: 3px;
          margin-left: 15px;
        }
      }

      a {
        color: $color14;
        font-size: 15px;
        text-decoration: none;
        display: block;
        min-height: 25px;
        line-height: 25px;
        padding: 0 15px;
        word-wrap: break-word;
        opacity: 1;
        transition: opacity 0.3s cubic-bezier(0.5, 0.31, 0.84, 0.05);

        &.active{
          border-left: 6px solid $color14;
          color: $color6;
          border-bottom: 0;
          padding-left: 9px;
        }
      }

      > a:hover, &.active > a {
        border-left: 6px solid $color14;
        color: $color6;
        border-bottom: 0;
        padding-left: 9px;
      }

      &.has_dropdown {
        &::after {
          content: '';
          background-image: url('../../lib/images/arrow_right.png');
          background-size: 18px;
          background-repeat: no-repeat;
          background-position: center;
          width: 18px;
          height: 18px;
          position: absolute;
          left: 227px;
          top: 18px;
          -webkit-transition: -webkit-transform .4s ease-in-out;
					transition: transform .4s ease-in-out;
        }

        &.active .dropdown_menu {
          opacity: 1;
					pointer-events: auto;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 85%;
    left: 0;

    &.opened {
      overflow: hidden;
    }

    .items{

      .item {

        .dropdown_menu {
          z-index: -1;
					right:0;
					transition:unset;
        }

        &.has_dropdown{

          &.active{
            background-color: $color3;
            .dropdown_menu {
              position: relative;
              top: auto;
              right: auto;
              border-radius: 0;
              margin-bottom: 0;
              z-index: 0;
            }
            &::after {
              -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
            }
          }
        }
      }
      padding-right: 0;

      .item{

        &.has_dropdown::after {
          left: 92%;
        }
        .dropdown_menu {
          width: 100%;
        }
        &.has_dropdown.active {
          border-left: $color14 solid 3px;
        }
        > a:hover, &.active > a {
          border-left: 3px solid $color14;
        }
      }
    }
  }
}
