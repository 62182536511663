@import "~brands/default/styles/globals";
.cards_table{
  font-family: "attalecksans_w_regular";
  .header{
    display: flex;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    min-height: 60px;
    background-color: $color3;
    border-top:1px solid $color4;
    border-bottom:1px solid $color4;
    color: $color8;
    font-weight: 700;
    font-size:12px;
    text-transform: uppercase;
  }

  .content_wrapper{
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;

    .column{
      flex:50%;
      padding: 10px;
      display: flex;
      align-items: center;
      font-size: 14px;

      &.full_width{
        flex: 100%;
      }
    }
  }
}

.pagination {
  padding: 20px;
  display: flex;
  justify-content: center;
  min-height: 67px;

  &.top{
    padding: 0;
  }
}

.sortby_wrapper{
  padding: 15px 15px 0 15px;

  .arrow_box {
    background: $color1;
    border: 1px solid $color4;
    width: 300px;
    border-radius: 7px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.12);
    padding: 0 9px;
    position: absolute;
    top:11px;
    left: 50%;
    margin-left: -71px;
    margin-top: 100%;
    z-index: 10;

    &:after, &:before{
      bottom: 100%;
      left: 70px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after{
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $color1;
      border-width: 10px;
      margin-left: -10px;
    }

    &:before{
      border-color: rgba(149, 149, 149, 0);
      border-bottom-color: $color4;
      border-width: 11px;
      margin-left: -11px;
      top:-22px;
    }
  }

  .tabs_list{
    
    li{
      min-height: 58px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 7px;
      cursor: pointer;

      &:not(:last-child){
        border-bottom:1px dashed $color5;
      }

      span{
        font-family: $primary-font-regular;
        font-size: 16px;
        color: $color8;
      }

      svg{
        height: 22px;

        path{
          fill: $color14;
        }
      }
    }
  }

    .active_tab{
      display: flex;
      align-items: center;
      font-family: $primary-font-medium;
      font-size: 14px;
      color: $color14;

      > span{
        display: inline-block;
      }

      .carret_wrap{
        position: relative;
        margin-right: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 14px;

        &.opened{
              
          > svg{
            transform: rotate(180deg);
          }
        }

        svg{
          height: 20px;

          path{
            fill: $color14;
          }
        }
      }
    }
}