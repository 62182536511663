@import "~brands/default/styles/globals";
.radio {
  line-height: $radio-line-height;
  margin: $radio-margin;

  label {
    position: relative;
    padding: $radio-label-padding;
    text-align: left;
    color: $radio-label-font-color;
    font-size: $radio-label-font-size;
    font-family: $radio-label-font-family;
    font-weight: 400;

    input[type="radio"] {
      position: absolute;
      top: 0px;
      background: transparent;
      height: 0;
      overflow: hidden;
      left: 0px;
      width: $radio-width;
      height: $radio-height;
      margin: 0px;
      border-radius: 50%;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      -moz-appearance: none;
      -webkit-appearance: none;
      border: 1px solid $radio-border-color-default;

      &:focus {
        overflow: visible;
      } // radio input default focus

      &:focus::before {
        @include dsfocus();
      } // radio input default focus state

      &:disabled::after {
        cursor: not-allowed;
        color: $radio-disabled-checked-color;
        background-color: $radio-disabled-checked-color;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        border: 3px solid #fff;
      }

      &:checked::after {
        background-color: $radio-checked-background;
        border-radius: 100%;
        border: 3px solid $radio-checked-border-color;
        content: "";
        top: 0px;
        left: 0px;
        display: block;
        height: 22px;
        position: absolute;
        width: 22px;
        box-sizing: border-box;
      } // radio input default checked state

      &::after {
        content: "";
        border-radius: 100%;
        line-height: 2rem;
        border-radius: 3px;
        display: inline-block;
        height: 24px;
        left: 0;
        position: absolute;
        margin-right: 10px;
        top: 0;
        width: 24px;
        box-sizing: border-box;
      } // radio input default styling
    } // radio input default
  } // radio default label

  &.disabled {
    label {
      input[type="radio"] {
        &:checked:after {
          cursor: not-allowed;
          color: $radio-disabled-checked-color;
          background-color: $radio-disabled-checked-color;
        } // radio disabled checked styling

        &:after {
          border: 3px solid $radio-disabled-border-color;
          background: $radio-disabled-background;
        } // radio disabled state styling
      } // radio disabled input
    } // radio disabled label
  } // radio disabled state

  &.has-error {
    label {
      color: $radio-error-label-color;

      input[type="radio"] {
        border: 1px solid $radio-error-border-color;

        &:checked::after {
          color: $radio-error-checked-background;
          background: $radio-error-checked-background;
        } // radio error checked state styling
      } // radio error input
    } // radio error state label
  } // radio error state
} // radio
