@import "~brands/default/styles/globals";

:global{

		.pagination {
			display: flex;
			padding-left: 0;
			margin: 20px 0;
			border-radius: 4px;
			align-items: center;
		}
		.pagination > li {
			display: inline-block;
			min-width: 33px;
			text-align: center;
		}
		.pagination > li > a,
		.pagination > li > span {
			position: relative;
			display: inline-block;
			line-height: 1.42857143;
			text-decoration: none;
			color: $pagination-color;
			min-width: 23px;
			font-size: 16px;
			text-align: center;
			padding-bottom:3px;
      font-family:$primary-font-regular;
			cursor: pointer;
			height: 100%;
      border-bottom: 2px solid transparent;
      margin-left: 4px;
      margin-right: 4px;
		}
		.previous{
				color:transparent;
				background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzNiAzNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzYgMzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7fQ0KCS5zdDF7ZmlsbDojMDU2OGFlO30NCjwvc3R5bGU+DQo8Zz4NCgk8cmVjdCB4PSIwIiB5PSIwIiBjbGFzcz0ic3QwIiB3aWR0aD0iMzYiIGhlaWdodD0iMzYiLz4NCgk8cGF0aCBjbGFzcz0ic3QxIiBkPSJNMjIuOSwzMC43Yy0wLjYsMC0xLjItMC4yLTEuNy0wLjdsLTkuNi05LjZjLTAuNi0wLjYtMS0xLjUtMS0yLjRjMC0wLjksMC40LTEuOCwxLTIuNEwyMS4zLDYNCgkJYzAuOS0wLjksMi41LTAuOSwzLjQsMGMwLjksMC45LDAuOSwyLjUsMCwzLjRMMTYsMThsOC42LDguNmMwLjksMC45LDAuOSwyLjUsMCwzLjRDMjQuMSwzMC40LDIzLjUsMzAuNywyMi45LDMwLjciLz4NCjwvZz4NCjwvc3ZnPg0K');
				background-size: 20px;
				display: inline-block;
				width: 20px;
				height: 20px;
				background-repeat: no-repeat;
				background-position: center;
				&:hover{
					filter: grayscale(100%);
				}
		}
		.previous.disabled{
			filter: grayscale(100%);
		}
		.next{
			color:transparent;
			background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzNiAzNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzYgMzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7fQ0KCS5zdDF7ZmlsbDojMDU2OGFlO30NCjwvc3R5bGU+DQo8Zz4NCgk8cmVjdCB4PSIwIiB5PSIwIiBjbGFzcz0ic3QwIiB3aWR0aD0iMzYiIGhlaWdodD0iMzYiLz4NCgk8cGF0aCBjbGFzcz0ic3QxIiBkPSJNMTMuMSwzMC43Yy0wLjYsMC0xLjItMC4yLTEuNy0wLjdjLTAuOS0wLjktMC45LTIuNSwwLTMuNEwyMCwxOGwtOC42LTguNmMtMC45LTAuOS0wLjktMi41LDAtMy40DQoJCWMwLjktMC45LDIuNS0wLjksMy40LDBsOS42LDkuNmMwLjYsMC42LDEsMS41LDEsMi40YzAsMC45LTAuNCwxLjgtMSwyLjRMMTQuOCwzMEMxNC4zLDMwLjQsMTMuNywzMC43LDEzLjEsMzAuNyIvPg0KPC9nPg0KPC9zdmc+DQo=');
			background-size: 20px;
			display: inline-block;
			width: 20px;
			height: 20px;
			background-repeat: no-repeat;
			background-position: center;
			&:hover{
				filter: grayscale(100%);
			}
		}
		.next.disabled{
			filter: grayscale(100%);
		}
		.pagination > li:first-child > a,
		.pagination > li:first-child > span {
			margin-left: 0;
			border-bottom-left-radius: 4px;
			border-top-left-radius: 4px;
		}
		.pagination > li:last-child > a,
		.pagination > li:last-child > span {
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;
		}
		.pagination > li > a:hover,
		.pagination > li > span:hover,
		.pagination > li > a:focus,
		.pagination > li > span:focus {
			z-index: 2;
		}
		.pagination > .active > a,
		.pagination > .active > span,
		.pagination > .active > a:hover,
		.pagination > .active > span:hover,
		.pagination > .active > a:focus,
		.pagination > .active > span:focus {
			z-index: 3;
			cursor: default;
			border-bottom:2px solid $pagination-color;
			color:$pagination-active-color;

		}
		.pagination > .disabled > span,
		.pagination > .disabled > span:hover,
		.pagination > .disabled > span:focus,
		.pagination > .disabled > a,
		.pagination > .disabled > a:hover,
		.pagination > .disabled > a:focus {
			color: $pagination-color;
			cursor: not-allowed;
			outline: none;
		}

		.pagination > .disabled > a{
			span[aria-label="Previous"],
			span[aria-label="Next"]{
				opacity: 0.57;
				filter:grayscale(100%);
			}
		}

		.pagination-lg > li > a,
		.pagination-lg > li > span {
			padding: 10px 16px;
			font-size: 18px;
			line-height: 1.3333333;
		}
		.pagination-lg > li:first-child > a,
		.pagination-lg > li:first-child > span {
			border-bottom-left-radius: 6px;
			border-top-left-radius: 6px;
		}
		.pagination-lg > li:last-child > a,
		.pagination-lg > li:last-child > span {
			border-bottom-right-radius: 6px;
			border-top-right-radius: 6px;
		}
		.pagination-sm > li > a,
		.pagination-sm > li > span {
			padding: 5px 10px;
			font-size: 12px;
			line-height: 1.5;
		}
		.pagination-sm > li:first-child > a,
		.pagination-sm > li:first-child > span {
			border-bottom-left-radius: 3px;
			border-top-left-radius: 3px;
		}
		.pagination-sm > li:last-child > a,
		.pagination-sm > li:last-child > span {
			border-bottom-right-radius: 3px;
			border-top-right-radius: 3px;
		}
	}
