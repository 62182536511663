@import "~brands/default/styles/globals";
:global {
  $white: #fff !default;
  $black: #000 !default;
  $Steps-color-neutral: #ccc !default;
  $Steps-color-active: #75b2f5 !default;
  $Steps-color-secondary: #4d637b !default;
  $Steps-color-complete: #05ae0e !default;
  $Steps-container-width: 100% !default;
  $Steps-step-width-height: 64px !default;
  $Steps-step-font-size: 24px !default;

  .Steps {
    //max-width: $Steps-container-width;
    //margin: 0 auto;

    .modal-footer {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
    }
  }

  .ds-steps-indicator {
    height: auto;
    padding:20px 0px;
    display: flex;
    height: 5px;
    position: relative;

    .step-indicator {
	  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		-ms-flex: 1 1 auto;
		flex-basis: auto;
	  }

      height: 5px;
      color: #767676;
      -ms-flex: 1;
      flex: 1;
      margin-right: 5px;
      background-color: #d2d2d2;
      outline: 1px solid transparent;
      transition: all 0.3s ease-in-out;

      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }

      &:last-of-type {
        margin-right: 0;
        border-radius: 0 4px 4px 0;
      }

      .step-text {
        bottom: 22px;
        display: inline-block;
        font-size:14px;
        margin-right: 12px;
        margin-top: 0;
        position: relative;
        white-space: nowrap;
        font-family: $primary-font-regular;
      }

      &-active,
      &-visited,
      &-completed {
        background-color: #007a3e;
        color: #007a3e;
        border: 1px solid transparent;

        .step-text {
          font-family: $primary-font-medium;
        }
      }

      &-error {
        background-color: #cf2a2a;
        color: #cf2a2a;

        .step-text {
          color: #cf2a2a;
        }
      }
    }
  }

  .StepsSteps {
    width: 100%;
    position: relative;
    display: table;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    border-collapse: separate;
    margin-left: 0px;
    padding-left: 0;
    padding-top: 20px;

    .StepsStep {
      display: table-cell;
      position: relative;
      float: none;
      padding: 0;
      width: 1%;

      .StepsStep-Icon {
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $Steps-color-neutral;
        color: $Steps-color-neutral;
        font-size: $Steps-step-font-size;
        height: $Steps-step-width-height;
        line-height: $Steps-step-width-height;
        margin: 0 auto;
        position: relative;
        width: $Steps-step-width-height;
        z-index: 1;
      }

      &.StepsStep-Active {
        .StepsStep-Icon {
          border-color: $Steps-color-active;
          color: $Steps-color-active;
        }
      }

      &.StepsStep-Complete {
        .StepsStep-Icon {
          border-color: $Steps-color-complete;
          color: $Steps-color-complete;
        }
      }

      &:after {
        left: 50%;
        background-color: $Steps-color-neutral;
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        width: 100%;
        top: $Steps-step-width-height / 2;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  .Steps-Actions {
    display: block;
		padding: 20px 0;
		text-align: right;

		.backButton{
			margin-right:20px;
		}
	}

	//Onboarding steps wrapper
	.wrapper{
		margin-top:50px;
		margin-left:15px;
		margin-right:15px;
		position: relative;
		.ds-steps-indicator{
			padding:0;
		}
		.onboardingBack{
			position: absolute;
			bottom: 20px;
			left:0;
		}
  }

  .no-side-modal-items{

    .ds-steps-indicator{
			padding:20px 0;
    }

    .modal-body{
      padding: 0 0 20px 0;
    }
  }
}

