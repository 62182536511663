@import "~brands/default/styles/globals";
.modalFooter{
  display: flex;
  justify-content: flex-end;
  height: auto;
  background-color: #f2f2f2;

  .saveButton, .cancelButton, .leftSideButton{
    align-self: flex-end;
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    border: none;
    min-width: 100px;
    height: 40px;
    cursor: pointer;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }
  .saveButton{
    background-color: #1E90FF
  }

  .saveButton:hover{
    background-color: #6495ED
  }

  .leftSideButton{
    justify-self: flex-start;
  }

  .cancelButton{
    border: 1px solid #B0C4DE;
    background: none;
    color: black;
    background: white;
  }
}


.modal_footer_custom_default{
  padding: 17px 30px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
  background-color: #f2f2f2;
  border-top: 1px solid #d2d2d2;
  /* width: 100%; */
}