@import "~brands/default/styles/globals";
@import './common.scss';

.modalBody{
  text-align: left;
  overflow-y: auto;
  height: 350px;
  width: 850px;
  max-height: $modal-body-max-height;

  @media screen and (max-width: 770px) {
    width: 100%;
    height: auto;
    min-height: $modal-body-mobile-min-height;
  }
}