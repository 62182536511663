@import "~brands/default/styles/globals";
@mixin showShadow($child:2){
  tr{

    td, th{
      &:first-child{
        background-color:#f2f2f2;
      }

      &:nth-child(#{$child}){
        position: relative;
        background-color:#f2f2f2;

        &:after {
          content: " ";
          height: 100%;
          top: 0;
          background-color: $color4;
          position: absolute;
          width: 1px;
          box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.3);
          right: 1px;
        }
      }
    }

    &.selected{

      td, th{
        &:first-child{
          background-color: #e2e2e2;
        }

        &:nth-child(#{$child}){
          background-color: #e2e2e2;
        }
      }

      &:hover{

        td, th{
          &:first-child{
            background-color: #f9f9f9;
          }

          &:nth-child(#{$child}){
            background-color: #f9f9f9;
          }
        }
      }
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
  letter-spacing: 0.4px;

  thead {
    background-color: $color3;
    font-size: 12px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-weight: 500;
    color: $color8;
    transition: position 0.5s ease;

    tr:hover{
      background-color: $color3;
    }

    th{

      > div{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  tr {
    height: 60px;
    border-top: 1px solid $color4;

    &.row_clickable{
      cursor: pointer;
    }

    &:last-child {
      border-bottom: 1px solid $color4;
    }

    td,th {
      text-align: left;
      padding: 0 10px 0 15px;

      &.checkbox{
        width: 40px;
        padding-right: 5px;
        touch-action: manipulation;
      }

      .select{
        margin-bottom: 0;
        width: 130px;
      }
    }

    &:hover{
      background-color: #f9f9f9;
    }

    &.selected{
      background-color: #e2e2e2;

      &:hover{
        background-color: #f9f9f9;
      }
    }
  }

  &.header_fixed{
    table-layout: fixed;

    thead {
      position: fixed;
      left: 220px;
      z-index: 1;
      padding-left: 227px;
      top: 58px;
      animation: smoothScroll 0.5s forwards;

      @keyframes smoothScroll {
        0% {
          transform: translateY(-40px);
        }
        100% {
          transform: translateY(0px);
        }
      }

      tr{
        background-color: $color3;

        th, td {

          &:nth-child(1) {
            z-index: 3;
          }

          &:nth-child(2) {
            z-index: 2;

            > div {
              width: 158px;
            }

            border-bottom: 1px solid $color4;
            height: 61px !important;
          }

          &:nth-child(1) {
            border-bottom: 1px solid $color4;
          }

          &.checkbox {
           z-index: 3;
           border-bottom: 1px solid $color4;
           height: 61px !important;
           touch-action: manipulation;
          }
        }
      }
    }

    tbody{
      display: block;
      padding-top: 60px;
    }
  }
}

div{

  &.draggable{

    img{
      align-self: center;
      cursor: pointer;
    }

    .dragicon{
      position:absolute;
      top:-28px;
      left:50%;
      margin-left:-14px;
      cursor:move;
      opacity: 0;


      svg{
        height:29px;
		width:29px;
		pointer-events: none;

		path{
          fill: $color14;
        }
      }
    }
  }
}

.th_draggable{

  > div{
    position: relative;
  }

  &:hover{

  div{

      &.draggable{

        .dragicon{
          opacity: 1;
        }
      }
    }
  }
}

.pagination {
  padding: 20px;
  display: flex;
  justify-content: center;
  min-height: 67px;

  &.top{
    padding: 0;
  }
}

.first_column_with_checkbox {
 @include showShadow(2);
}

.first_column_no_checkbox{
  @include showShadow(1);
}

.sort_arrows{
  height: 22px;
	cursor: pointer;
	width: 22px;

  path{
    fill: $color14;
  }
}

:global {

  .drag_column{
    border-bottom:2px dashed $color14 !important;
  }
}

.table_wrapper {
  overflow: auto;
}

/* fixed first column start */
.fixed_first_column{

  .table_wrapper{
    width:calc(100% - 227px);
    overflow-x:auto;
    margin-left:227px;
    overflow-y:visible;
    padding-bottom:1px;

    &.active{
      cursor: grabbing;
    }

    .table{

      tr{

        th, td {
          min-width: 150px;

          // FIRST COLUMN WHEN fixedFirstColumn=true && selectRows=false
          &:first-child {
            position: absolute;
            left: 0;
            top: auto;
            border-top: 1px solid $color4;
            margin-top:-1px;
            height: 60px;
            min-width: 227px;

            > div {
              display: flex;
              height: 100%;
              align-items: center;
              max-width: 200px;
            }
          }

          // SECOND COLUMN WHEN fixedFirstColumn=true && selectRows=false
          &:nth-child(2) {
            position: relative;
            left: auto;
            padding: 0 10px 0 15px;
            width: auto;
          }

          &.checkbox {
            // FIRST COLUMN WHEN fixedFirstColumn=true && selectRows=true
            position:absolute;
            left:0;
            top:auto;
            border-top: 1px solid $color4;
            margin-top:-1px;
            height: 60px;
            touch-action: manipulation;

            min-width: 0;
            width: 59px;

            > div {
              display: flex;
              height: 100%;
              align-items: center;
            }

            // SECOND COLUMN WHEN fixedFirstColumn=true && selectRows=true
            &+th, &+td {
              position:absolute;
              left:0;
              top:auto;
              border-top: 1px solid $color4;
              margin-top:-1px;
              height: 60px;

              left: 59px;
              min-width: 0;
              width: 170px;
              padding-left: 0;

              > div {
                display: flex;
                height: 100%;
                align-items: center;
              }
            }
          }
        }

        &:last-child {
          td {
            &:first-child, &:nth-child(2) {
              border-bottom: 1px solid $color4;
            }
          }
        }
      }
    }
  }
}
/* fixed first column end */

/* mobile start */
.mobile_td{
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;

  svg {
    height: 20px;

      path{
        fill:$color4;
      }
  }
}
/* mobile end */
