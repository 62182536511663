@import "~brands/default/styles/globals";
.button {
  min-width: 150px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: $buttons-padding;
  font-size: $buttons-font-size;
  line-height: $buttons-line-height;
  border-radius: $buttons-border-radius;
  font-family: $buttons-font-family;
  box-shadow: $buttons-box-shadow;
  height: $buttons-height;
  border: $buttons-border;
  color: $color1;
  outline: none;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:disabled {
    color: $button-disabled-color;
    background-color: $button-disabled-background-color;
    box-shadow: none;
    cursor: not-allowed;
    border-color: transparent;
    background-image: none;
    text-decoration: none;
  }

  @include mobile-landscape-and-below {
    min-width: 100%;
  }
}

.outline {
  background-color: $button-outline-background-color;

  &:focus {
    color: $color1;
    box-shadow: 0 0 0 2px $color1 inset;
  }

  &:hover:not([disabled]) {
    color: $color1;
    @include hollowhover();
  }
}

.primary {
  background-color: $button-primary-background-color;
  background-image: linear-gradient(
    to bottom,
    $button-primary-gradient-start 0%,
    $button-primary-gradient-end 100%
  );
  border-color: $button-primary-gradient-start $button-primary-gradient-start
    $button-primary-background-color $button-primary-gradient-start;

  &:focus {
    box-shadow: inset 0 0 0 2px $button-primary-hover-background,
      inset 0 0 0 3px $button-primary-hover-color,
      0 5px 5px -5px $button-primary-focus-box-shadow-color;
  }

  &:hover:not([disabled]) {
    background: $button-primary-hover-background;
    border-color: $button-primary-hover-border-color;
  }
}

.outline-primary {
  color: $button-hollow-blue-color;
  border: 2px solid $button-hollow-blue-color;

  &:focus,
  &:hover:not([disabled]) {
    background-color: $button-hover-hollow-blue-background-color;
  }
}

.secondary {
  background-color: $button-hollow-gray-color;
  background-image: linear-gradient(
    to bottom,
    $gradient_dark_fourth_color 0%,
    $gradient_dark_third_color 100%
  );
  border-color: $gradient_dark_fourth_color $gradient_dark_fourth_color
    $button-hollow-gray-color $gradient_dark_fourth_color;

  &:focus {
    box-shadow: inset 0 0 0 2px $button-hollow-gray-color,
      inset 0 0 0 3px $button-hover-hollow-gray-color,
      0 5px 5px -5px $button-primary-focus-box-shadow-color;
  }

  &:hover:not([disabled]) {
    background: $color6;
    border-color: $button-primary-hover-border-color;
  }
}

.outline-secondary {
  color: $button-hollow-gray-color;
  border: 2px solid $button-hollow-gray-color;

  &:focus,
  &:hover:not([disabled]) {
    background-color: $button-hover-hollow-gray-background-color;
  }
}

.success {
  background-color: $button-success-background-color;
  background-image: linear-gradient(
    to bottom,
    $button-success-gradient-start 0%,
    $button-success-gradient-end 100%
  );
  border-color: $button-success-gradient-start $button-success-gradient-start
    $button-success-gradient-end $button-success-gradient-start;

  &:focus {
    box-shadow: inset 0 0 0 2px $button-success-hover-background,
      inset 0 0 0 3px $color1,
      0 5px 5px -5px $button-success-focus-box-shadow-color;
  }

  &:hover:not([disabled]) {
    background: $button-success-hover-background;
    border-color: $button-success-hover-background;
  }
}

.outline-success {
  color: $button-success-background-color;
  border: 2px solid $button-success-background-color;

  &:focus,
  &:hover:not([disabled]) {
    background-color: $button-success-background-color;
  }
}

.warning {
  background-color: $button-warning-background-color;
  background-image: linear-gradient(
    to bottom,
    $button-warning-gradient-start 0%,
    $button-warning-gradient-end 100%
  );

  &:focus {
    box-shadow: inset 0 0 0 2px $button-warning-hover-background,
      inset 0 0 0 3px $button-warning-hover-color,
      0 5px 5px -5px $button-warning-focus-box-shadow-color;
  }

  &:hover:not([disabled]) {
    background: $button-warning-hover-background;
    border-color: $button-warning-hover-border-color;
  }
}

.outline-warning {
  color: $button-warning-background-color;
  border: 2px solid $button-warning-background-color;

  &:focus,
  &:hover:not([disabled]) {
    background-color: $button-warning-background-color;
  }
}

.danger {
  background-color: $button-danger-background-color;
  background-image: linear-gradient(
    to bottom,
    $button-danger-gradient-start 0%,
    $button-danger-gradient-end 100%
  );
  border-color: $button-danger-gradient-start $button-danger-gradient-start
    $button-danger-gradient-end $button-danger-gradient-start;

  &:focus {
    box-shadow: inset 0 0 0 2px $button-danger-background-focus-color,
      inset 0 0 0 3px $color1,
      0 5px 5px -5px $button-success-focus-box-shadow-color;
  }

  &:hover:not([disabled]) {
    background: $button-danger-background-focus-color;
    border-color: $button-danger-background-focus-color;
  }
}

.outline-danger {
  color: $button-danger-outline-color;
  border: 2px solid $button-danger-outline-color;

  &:focus,
  &:hover:not([disabled]) {
    background-color: $button-danger-background-focus-color;
    border-color: $button-danger-background-focus-color;
  }
}

.link {
  color: $button-link-color;
  font-weight: normal;
  border-radius: 0;
  border-color: transparent;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;

  &:hover:not([disabled]),
  &:focus {
    text-decoration: underline;
    &:focus {
      outline: thin dotted $button-link-focus-outline-color;
    }
  }

  &:disabled {
    color: $button-disabled-color;
    background-color: transparent;
    box-shadow: none;
    cursor: not-allowed;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  > i {
    margin-right: 5px;
    animation: spinner 1s linear infinite;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNiAzNiI+CiAgPHBhdGggZmlsbD0iIzA1NjlBRSIgZD0iTTE4IDM2Yy0yLjcgMC00LjgtLjYtNi4yLTFDOCAzMy42IDUuNCAzMS4xIDQgMjkuMyAyLjcgMjcuNi45IDI0LjcuMyAyMC44Yy0uMy0yLjEtLjUtNS40LjktOS4xQzMgNi44IDYuNCA0LjEgOC4zIDIuOCAxMS44LjUgMTUuNCAwIDE3LjggMGwuMSA0LjNjLTEuOCAwLTQuNi40LTcuMyAyLjItMS40LjktNCAzLTUuNCA2LjctMSAyLjgtLjkgNS4zLS43IDYuOS41IDMgMS44IDUuMSAyLjkgNi40IDEuMSAxLjQgMyAzLjIgNiA0LjMgMS41LjUgNC42IDEuMyA4LjMuMyAzLS44IDUtMi41IDYuMi0zLjcgMS40LTEuNCAyLjQtMy4yIDMuMS01IC40LTEuMy45LTMuMy42LTUuOC0uNS00LjItMi44LTcuMS00LjItOC41TDMwLjUgNWMxLjggMS44IDQuOCA1LjYgNS40IDExLjEuMyAzLjItLjMgNS45LS45IDcuNi0uOCAyLjQtMi4yIDQuOC00IDYuNy0xLjUgMS42LTQuMiAzLjgtOC4xIDQuOS0xLjguNS0zLjQuNy00LjkuN3oiLz4KICA8cGF0aCBmaWxsPSIjRkZGIiBkPSJNMTcuOCAyLjJjMS44IDAgNC45LjMgOC4yIDIuMiAxLjIuNyAyLjIgMS41IDMgMi4zIi8+CiAgPHBhdGggZmlsbD0iI0YyRjJGMyIgZD0iTTI3LjUgOC4xYy0uOC0uOC0xLjctMS40LTIuNi0xLjktMi4xLTEuMi00LjUtMS45LTcuMS0xLjlWMGMzLjMgMCA2LjUuOSA5LjMgMi40IDEuMi44IDIuMyAxLjYgMy40IDIuNmwtMyAzLjF6Ii8+Cjwvc3ZnPg==");
  }
}

.icon {
  svg {
    width: 22px;
    height: 28px;
    right:5px;
    position: relative;
    g {
      fill: $color1;
      path {
        fill: $color1;
      }
    }
  }
}
